@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
   h1 {
      @apply font-[Poppins] font-bold text-white
      text-[24px] leading-[28px]
      sm:text-[40px] sm:leading-[48px]
      lg:text-[48px] lg:leading-[54px]
   }
   h2 {
      @apply font-[Poppins] font-bold text-azuli text-center
      text-[32px] leading-[40px]
      sm:text-[38px] sm:leading-[44px]
      lg:text-[42px] lg:leading-[48px]
   }
   h3 {
      @apply font-[Poppins] font-bold text-black text-center
      text-[28px] leading-[32px]
      sm:text-[34px] sm:leading-[36px]
      lg:text-[40px] lg:leading-[40px]
   }
   h4 {
      @apply font-[Inter] font-bold text-white
      text-[12px] sm:text-[24px]
   }

   h5 {
      @apply font-Nsans font-semibold text-[#1E2A4B]
      text-[18px] sm:text-[24px]
   }

   h6 {
      @apply font-Nsans text-white text-center uppercase
      text-[24px] sm:text-[32px]
   }

   button, a{
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
   }

   .squiggle {
      color: #18272F;
      position: relative;
      text-decoration: none;
    }
    
    .squiggle::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      border-radius: 4px;
      background-color: #18272F;
      bottom: 0;
      left: 0;
      transform-origin: right;
      transform: scaleX(0);
      transition: transform .3s ease-in-out;
    }
    
    .squiggle:hover::before {
      transform-origin: left;
      transform: scaleX(1);
    }
}

@layer components {
   .pNav{
      @apply font-Nsans font-bold cursor-pointer
      text-center text-[18px] 
      sm:font-black 
   }
   .menuOptions{
      @apply w-full text-white h-full py-[32px]
      cursor-pointer border-b-[0.25px] 
      hover:bg-[#EBC235] hover:text-[#131C37]
      flex justify-center items-center
   }
   .bgHero{
       @apply h-[62vh] bg-cover bg-center
       before:bg-black before:w-full before:h-full before:absolute before:opacity-50
   }
   .tabText{
      @apply font-[Inter] !font-normal 
      text-[18px] sm:text-[20px] lg:text-[24px]
   }
   .tabGalery{
      @apply !font-[Inter] !font-bold
      text-[12px] sm:text-[18px] lg:text-[24px]
   }
   .acordion{
      @apply bg-white rounded-[16px]
      p-[12px] md:px-[32px]
   }
   .ask{
      @apply font-Nsans font-medium text-azuli text-left
      text-[14px] leading-[15px]
      sm:text-[20px] sm:leading-[24px]
   }
   .answer{
      @apply font-Nsans font-normal text-left
      text-[14px] leading-[15px] pt-[16px]
      sm:text-[20px] sm:leading-[24px] sm:pt-[20px]
   }
   .tabTextDay {
      @apply font-[Inter] font-bold 
      text-[8px] mobile:text-[10px] md:text-[16px]
   }

   .p1 {
      @apply font-Nsans text-white   relative z-20
      text-[16px] pt-[8px] pb-[24px] uppercase max-w-[550px]
      sm:text-[30px] sm:leading-[40px] sm:pt-[16px] sm:pb-[32px]
   }

   .p5 {
      @apply font-Nsans !text-[#1E2A4B]
      text-[14px] sm:text-[20px]
   }
   .bgNewHero{
      @apply bg-cover min-h-[360px] items-center h-[100%]
      before:bg-gradient-to-r before:from-black before:to-transparent 
      before:w-full before:h-full before:absolute before:z-10
  }
  
}

@layer utilities {
   .pySections {
      @apply py-[40px] md:pt-[64px] md:pb-[72px]
   }
   .containerW-1048 {
      @apply max-w-[1048px] mx-auto 
      px-[4%] sm:px-[6%] md:px-[8%] xl:px-0
  }
  .containers{
      @apply w-full  min-h-[86vh]  md:min-h-[90vh] mx-auto max-w-[768px]
      px-[4%] lg:px-0
  }
  .gridGalery {
   @apply  grid grid-cols-2 gap-[16px]
   lg:grid-cols-3 sm:gap-[24px]
  }
  .containerSlideHero {
   @apply w-full max-w-[1192px] mx-auto !z-40 h-full
   py-[80px] sm:h-[420px] lg:h-[508px] lg:py-[112px]
   px-[5%] sm:px-[6%] xl:px-0
  }
}